import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Row, Col, Alert, DropdownButton, Dropdown, Toast } from 'react-bootstrap'
import { cap,pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/aulia.aac'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoigwhite from '../assets/img/aulia/logoigwhite.svg'
import logoig from '../assets/img/aulia/logoig.svg'
import burung from '../assets/img/aulia/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import getData from '../params/getdata'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

let cmain = '#69785b'
let black = 'rgb(38,38,38)'


export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()

    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: false,
      err: [],
      submitted: '',
      sesi: 1,
      comment: [],
      friend: null,
      show: false,
      show1: false,
      showgift: false,
      shownew: false
    }
  }

  dropdown = () => {
    let { friend } = this.state
    let item = []
    for (let index = 1; index < 6; index++) {
      item[index] = (<Dropdown.Item onClick={() => {
        this.setState({ friend: index })
      }}>{index}</Dropdown.Item>)
    }
    return (
      <>
        <Item>
          <p style={{ fontSize: '16px' }} className="mb-0 w-100 text-center">
            How many people you coming with?
      </p>
          <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
            {
              item
            }
          </DropdownButton>
        </Item>
      </>
    )
  }

  componentDidMount = async () => {
    try {
      AOS.init({
        // initialise with other settings
        duration: 2000
      });
      var countDownDate = new Date("11/29/2020").getTime();

      // Update the count down every 1 second
      var x = setInterval(() => {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.setState({
          days: days, hours: hours, minutes: minutes, seconds: seconds
        })
      }, 1000);


      let comment =await getData(`kepada:"aulia-yossa"`)
      this.setState({ comment: comment })
    } catch (error) {
      console.log(error)
    }

  }

  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    console.log(local)
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "aulia-yossa", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift, comment, shownew, sesi } = this.state
    let slide = [
      "	https://i.ibb.co/TwprxnJ/1605627594774-uri-mr1605627593912-mh1605627684199-01.jpg	",
      "	https://i.ibb.co/Ks94HpF/DSC03010.jpg	",
      "	https://i.ibb.co/g6VTxK5/P1190169-01-01.jpg	",
      "	https://i.ibb.co/WPk0hdC/P1190523.jpg	",
      "	https://i.ibb.co/ZgJYdh4/P1190658-mr1605640356208-01.jpg	",
      "	https://i.ibb.co/przh1My/P1190916-mr1605637943461-01.jpg	",
      "	https://i.ibb.co/Mn4Qd66/01-mr1605628063709.jpg	",
      "	https://i.ibb.co/SdVbkJY/03-1-01.jpg	",
    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=480'))
    })
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''

    return (
      <>
        <Helm
          title='Undanganku - Aulia & Yossa'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={'https://i.ibb.co/SdVbkJY/03-1-01.jpg'}
          url={`https://undang.in/aulia-yossa`}
        />

        <div id='gold5' style={{
          backgroundImage: `url(${gambar('https://i.pinimg.com/originals/ce/fc/80/cefc801a4c1d6fe986e225e2182275af.jpg', 95, 'auto&blur=8&bright=20')})`,
          backgroundSize: '100%',
        }}>
          {
            this.useQuery().get('x') == "x" ? (<Float />) : false
          }
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw',
            backgroundColor: 'transparent'
          }}>
            <Container fluid id='g3-header' className='relative pt-0' style={{
              backgroundImage: `url(${hide ? 'https://i.ibb.co/hcn5pPG/Modal.jpg' : gambar('https://i.ibb.co/gg8h9FG/cover.jpg', 95, 'auto&brigt=20')})`
            }}>
              <Item>
                <Col xs={12} md={4} className='m-2 m-md-0 '>
                  <img className='img-fluid w-100 p-2'
                    src={!hide ? "" : gambar('https://i.ibb.co/yszCwXD/bitmapwhsaite.png')} data-aos="fade-left" />
                </Col>
              </Item>

              <Row className='justify-content-center'>
                <div onClick={() => { this.play() }}

                  className={` btn col-md-4 button roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                  style={{ marginTop: 0, color: 'white' }}>
                  Open Invitation
                            </div>
              </Row>
              <Item>
                {
                  <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-md-3`} style={{ marginTop: '0' }}>
                    <br /> {query ? `Kepada Yth : ${query}` : ''} <br /></h2>
                }</Item>
            </Container>

            <div className={hide ? 'd-none' : 'd-block'}>
              <div id="top" style={{ backgroundColor: 'transparent' }}>
                <Container >
                  <Item >
                    <Col xs={10} md={6}>
                      <img src={gambar('https://i.ibb.co/cFtJ3sY/Logo-Aulia-Dalam.png')} className='w-100 img-fluid' />
                    </Col>
                  </Item>
                </Container>
                <Container className="dinny px-4 pt-2 ">
                  <Item>

                    <p className="fs24 text-center cblack">
                      <span className="fs16">

                      “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.”
                                    <br /><br />
                                        <span style={{ color: 'black' }}>(Ar-Rum: 21)
                    </span>
                    </span>
                    </p>
                  </Item>
                  <Item>
                    <Col xs={6} md={4}>
                      <img src='https://webstockreview.net/images/clipart-flower-hipster-2.png' data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                      The Intimate Wedding of Aulia & Yossa
                              </p>
                  </Item>
                </Container>
                <Container id='sectiongold57' className="py-3">
                  <div className='pt-3'>
                    <Item>
                      <div data-aos="fade-left" data-aos-duration="1000"
                        className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                        <Item>
                          <div className='item'>
                            <Item>
                              <div>
                                {days}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Days
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {hours}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Hours
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div >
                                {minutes}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Mins
                      </span>
                            </Item>
                          </div>
                          <div className='dot' >:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {seconds}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Secs
                      </span>
                            </Item>
                          </div>
                        </Item>

                      </div>
                    </Item>
                  </div></Container>

                <Container id='sectiongold55' className="py-5 aulia" fluid style={{ backgroundColor: cmain }}>
                  <Item>
                    <Col md={10}>
                        <Item>
                            <p className="w-100 text-center" 
                            style={{fontSize:'24px',fontFamily: "'Marck Script', cursive",
                            color:'white'
                            }}>
                            Assalamu'alaikum Warahmatullahi Wabarakatuh
 
                            </p>
                            <p className="w-100 text-center" 
                            style={{fontSize:'18px',
                            color:'white'
                            }}>
                                
Dengan menyebut nama Allah Subhanahu Wa Ta'ala Maha Pengasih dan Maha Penyayang. Kami mengundang Anda untuk menghadiri pernikahan kami.
                                </p>
                        </Item>
                      <Item>
                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                          <div className='kotak mr-lg-2'>

                            <Item>
                              <Col xs={6}>
                                <img src={gambar('https://i.ibb.co/87cLrjk/Individu-1.jpg', 90)} className='img-fluid w-100' />
                              </Col>
                            </Item>
                            <Item>
                              <h1 style={{ fontSize: '72px', color: cmain, lineHeight: 1.2 }} className="haviland">
                                Aulia
                          </h1>
                            </Item>
                            <Item>
                              <h1 className="nama-mempelai text-center tinos rev1"
                                style={{ color: cmain, }}>
                                HUSNA DAYA AULIA, S.Pi
                          </h1>
                            </Item>
                            <Item>
                              <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                <b>Puteri Pertama  dari: </b><br />
                            Bapak Drs. Zulkarnain Al-Fath <br />
                        &<br />
                        Ibu Wahyu Afriani
                      </p>
                            </Item>
                            <Item>
                              <Col xs={4} md={3}
                                onClick={() => { window.open('https://instagram.com/hdaulia') }}>
                                <img src={logoig} className='btn img-fluid w-100' />
                              </Col>
                            </Item>
                          </div>
                        </div>
                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                          <div className='kotak mr-lg-2'>

                            <Item>
                              <Col xs={6}>
                                <img src={gambar('https://i.ibb.co/5GhGZPg/Individu-2.jpg', 90)} className='img-fluid w-100' />
                              </Col>
                            </Item>
                            <Item>
                              <h1 style={{ fontSize: '72px', color: cmain, lineHeight: 1.2 }} className="haviland">
                                Yossa
                      </h1>
                            </Item>
                            <Item>
                              <h1 className="nama-mempelai text-center tinos rev1" style={{ color: cmain }}>
                                YOSSA AGUNG PRATAMA, M.Kom
                      </h1>
                            </Item>
                            <Item>
                              <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                <b>Putera pertama dari : </b><br />
                            Bapak Busono
                        <br />
                        &<br />
                        Ibu Hj. Sri Martiningsih, S.Pd
                      </p>
                            </Item>
                            <Item>
                              <Col xs={4} md={3}
                                onClick={() => { window.open('https://instagram.com/yossaagungp') }}>
                                <img src={logoig} className='btn img-fluid w-100' />
                              </Col>
                            </Item>
                          </div>
                        </div>
                      </Item>

                    </Col>
                  </Item>
                </Container>
                <div style={{
                  backgroundImage: `url(${gambar('https://i.pinimg.com/originals/ce/fc/80/cefc801a4c1d6fe986e225e2182275af.jpg', 95, 'auto&blur=8&bright=20')})`,
                  backgroundSize: '100%',
                }}>


                  <Container fluid className="text-center px-4 dinny py-3" style={{ color: black }} >
                    <Item>
                      <p className="fs16">
                        Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                    </Item>
                    <Item>
                      <p className="fs20">
                        <b>
                          MINGGU <span className="fs36">29</span> NOV 2020
                      </b>
                      </p>
                    </Item>
                    <Item>
                      <p className="fs20 col-md-4" style={{ color: cmain }}>
                        <div className="butterfly" style={{ fontStyle: 'bold', fontSize: '36px' }}><b>Akad Nikah </b><br /></div>
                        <span className="cblack fs16">
                          08.00 WIB - Selesai
                      </span><br />
                      </p>
                      <p className="px-3 d-none d-md-block" style={{ color: cmain, fontSize: '72px' }}>
                        \
                    </p>
                      <div className="col-8 d-md-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                      </div>
                      <p className="fs20 pt-3 pt-md-0 col-md-4" style={{ color: cmain }}>
                        <div className="butterfly" style={{ fontSize: '36px' }}><b>Resepsi</b><br /> </div>
                        <span className="cblack fs16" style={{ fontStyle: 'bold' }}>
                          10.00 WIB - Selesai
                      </span> <br />
                        <span className="cblack fs12">

                        </span>
                      </p>
                    </Item>
                    <Item>
                      <p className="fs16 pt-3">
                        <img src='https://www.flaticon.com/svg/static/icons/svg/2928/2928883.svg' className='w-25 px-5 img-fluid' /><br />                    <span >
                          Jl. Jambi - Palembang KM 27 RT. 007 Kelurahan Tempino Muaro Jambi

                    </span>
                      </p>
                    </Item>
                    <Item>
                      <Col xs={12} md={8}>
                        <img src={gambar('https://i.ibb.co/CVS12YR/Denah-Lokasi.png', 90)} className="img-fluid w-100" />
                      </Col>
                    </Item>
                    <Item>
                      <Col xs={10} md={3}
                        style={{
                          border: `2px solid ${cmain}`,
                          borderRadius: '10px'
                        }}
                        onClick={() => {
                          window.open("https://goo.gl/maps/apUzCKNHW5kFtuke7")
                        }}
                        className="btn p-2 mx-md-2">
                        <Item>
                          <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                            style={{ width: "10%", height: '10%' }} />
                          <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                            <b>Get Direction</b>
                          </p>
                        </Item>
                      </Col>
                      <Col
                        onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=MGx2aXEzMWEwaHFuNHVzNm8xb3JubW5xcDEgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                        xs={10} md={3}
                        style={{
                          border: `2px solid ${cmain}`,
                          borderRadius: '10px'
                        }}
                        className="btn p-2 mx-md-2 mt-3 mt-md-0">
                        <Item>
                          <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                            style={{ width: "10%", height: '10%' }} />
                          <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                            <b>Add to Calendar</b>
                          </p>
                        </Item>
                      </Col>
                    </Item>
                    <Container className="py-3">
                      <Item>
                        <Col
                          onClick={() => this.setState({ shownew: !shownew })}
                          xs={11} md={4}
                          style={{
                            border: `2px solid ${cmain}`,
                            borderRadius: '10px'
                          }}
                          className="btn p-2 mx-2 mt-3 mt-md-0">
                          <Item>
                            <img src="https://www.flaticon.com/svg/static/icons/svg/2750/2750740.svg" className="img-fluid"
                              style={{ width: "10%", height: '10%' }} />
                            <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                              <b>New Normal Rules</b>
                            </p>
                          </Item>
                        </Col>
                      </Item>
                    </Container>

                    <Container className={`text-center dinny pb-3  ${!shownew ? 'trsnone' : 'trs'}`}>
                      <Item>
                        <Col className="py-3 px-md-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                          <h1 className="fs30"
                            style={{

                              fontFamily: "'Marck Script', cursive",
                              color: '#B99225'
                            }}>
                            New Normal Rules
                          </h1>
                          <Item>
                            <Col xs={12}>
                              <p className="cblack text-center w-100 fs16">
                                Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                            </Col>
                            <Col xs={6} md={4}>
                              <img src={mask} className="w-100 img-fluid p-md-4" />
                              <Row>
                                <p className="cblack text-center w-100 fs16">
                                  Gunakan Masker
                        </p>
                              </Row>
                            </Col>
                            <Col xs={6} md={4}>
                              <img src={distance} className="w-100 img-fluid p-md-4" />
                              <Row>
                                <p className="cblack text-center w-100 fs16">
                                  jaga Jarak
                      </p>
                              </Row>
                            </Col>
                            <Col xs={6} md={4}>
                              <img src={salaman} className="w-100 img-fluid p-md-4" />
                              <Row>
                                <p className="cblack text-center w-100 fs16">
                                  Cukup Bersalaman tanpa Bersentuhan
                      </p>
                              </Row>
                            </Col>
                          </Item>
                        </Col>
                      </Item>

                      <Item>
                        <Col>
                        </Col>

                      </Item>

                    </Container>

                  </Container>

                  <Container className='mt-3 py-3 ' data-aos="fade-right" data-aos-duration="1000">
                    <Item>
                      <div className="butterfly">
                        <h1 style={{ color: cmain, fontStyle: 'bold', fontWeight: 700, fontSize: '72px' }}>
                          The Moment
                        </h1>
                      </div>
                    </Item>
                    <Item>
                      <Col className="py-3">
                        <img src={gambar('https://i.ibb.co/SsxtzY7/image857.png', 95)} className="w-100  img-fluid" />
                      </Col>
                    </Item>
                    <Slider slide={slider} />
                  </Container>
                  <Container id='sectiongold56' className="py-3 px-4" >
                    <div className='pt-3'>
                      <div data-aos={`fade-right`} data-aos-duration="2000">
                        <Item>
                          <div className='kotak col-12 position-relative' style={{ backgroundColor: 'white', overflow: 'hidden' }}>
                            <div className="position-absolute w-50" style={{
                              top: 0, left: 0, zIndex: 0, opacity: 0.5
                            }}>
                              <img src={gambar('https://i.ibb.co/hyV37Bg/Daun-daunan.png', 40)} className="w-100 img-fluid" />
                            </div>
                            <div className="position-absolute w-50" style={{
                              top: 0, right: 0, zIndex: 0, opacity: 0.5, transform: 'scaleX(-1)'
                            }}>
                              <img src={gambar('https://i.ibb.co/hyV37Bg/Daun-daunan.png', 40)} className="w-100 img-fluid" />
                            </div>
                            <Item>
                              <p className='text-center p-2 px-2 pt-5 amatic' style={{ zIndex: 1, color: 'black' }}>
                                <div style={{ fontWeight: 700 }} className="d-inline">50.000</div> years before the sky introduced to the sea, Allah wrote down your name next to me. No matter how long it takes, how far we go, how tough it seems, then finally we find each other (again).
                              </p>
                            </Item>
                          </div>
                        </Item>
                      </div>
                    </div>
                  </Container>
                </div>
                <Container id='sectiongold58' className="mt-3 py-3" fluid style={{ backgroundColor: cmain }}>
                  <Item>
                    <Col xs={11}>
                      <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                        <Item>
                          <Col xs={4} lg={2}>
                            <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <Col xs={12} md={6}>
                            <div className='col-12 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                              <Item>
                                <h1 style={{
                                  fontFamily: '"Marck Script", cursive',
                                  color: cmain
                                }}>
                                  Send Your Wishes
                              </h1>
                              </Item>
                              <Item>
                                <form className="col-12 w-100">
                                  <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                                  <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                  {
                                    this.dropdown()
                                  }
                                  <Item>
                                    <div id="formradio">
                                      <div class="custom_radio row justify-content-center">
                                        <div onClick={() => {
                                          this.setState({ hadir: true })
                                        }
                                        }>
                                          <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                          <label style={{ color: cmain }} for="featured-1">Hadir</label>
                                        </div>
                                        <div onClick={() => {
                                          this.setState({ hadir: false })
                                        }
                                        } className="pl-5">
                                          <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                          <label style={{ color: cmain }} for="featured-2"
                                          >Tidak Hadir</label>
                                        </div>
                                      </div>
                                    </div>
                                  </Item>
                                  {!hadir ? false : (
                                    <>
                                      <Alert variant='dark col-12 mr-4 '>
                                        <p style={{ fontSize: '16px' }} className="w-100 text-center">
                                          Silahkan Pilih Jadwal Kedatangan
                                      </p>
                                        <div id="formradio" className='pl-0'>
                                          <div class="custom_radio row justify-content-center">

                                            <div onClick={() => {
                                              this.setState({ sesi: 1 })
                                            }
                                            }
                                              className="pl-5">
                                              <input type="radio" id="featured-3" name="sesi" checked={sesi == 1 ? true : false} />
                                              <label for="featured-3">

                                                Sesi 1 : 10.00 - 12.00 WIB</label>
                                            </div>
                                            <div onClick={() => {
                                              this.setState({ sesi: 2 })
                                            }
                                            } className="pl-5">
                                              <input type="radio" id="featured-4" name="sesi" checked={sesi == 2 ? true : false} />
                                              <label for="featured-4"

                                              >Sesi 2 : 12.00 - 14.00 WIB</label>
                                            </div>

                                            <div onClick={() => {
                                              this.setState({ sesi: 3 })
                                            }
                                            } className="pl-5">
                                              <input type="radio" id="featured-5" name="sesi" checked={sesi == 3 ? true : false} />
                                              <label for="featured-5"
                                              >Sesi 3 : 14.00 - 16.30 WIB</label>
                                            </div>
                                          </div>
                                        </div>
                                        <p style={{ fontSize: '16px' }} className="w-100 text-center">
                                          Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan
                                      </p>
                                      </Alert>

                                    </>
                                  )}

                                  <Item>

                                    <Col xs={12} className='' >
                                      {
                                        submitted == true ? (
                                          <Alert variant='success' style={{ fontSize: '16px' }}>
                                            Pesan anda sudah disampaikan
                                          </Alert>) : (submitted === false ? (
                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                              {
                                                err.map(val => {
                                                  return (
                                                    <li>{val}</li>
                                                  )
                                                })
                                              }

                                            </Alert>
                                          ) : false)
                                      }

                                    </Col>
                                  </Item>
                                  <Item>
                                    <div className='btn col-6 button rounded'
                                      onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain }} no> Kirim </div>
                                  </Item>
                                </form>
                              </Item>
                            </div>

                          </Col>

                          <Col xs={12} md={6}>
                            <div className='col-12 kotak pb-4 pt-4 mt-3 mt-md-0' data-aos="left-left" data-aos-duration="1000" style={{ height: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                              {comment.map(v => {
                                return (
                                  <Item>
                                    <Col xs={2}>
                                      <img src='https://www.flaticon.com/svg/static/icons/svg/3447/3447670.svg' className="img-fluid w-100" />
                                    </Col>
                                    <Col xs={10}>
                                      <Alert variant="secondary col-12">
                                        <p style={{ fontSize: '16px' }} className='m-0'>
                                          {v.pesan}
                                        </p>
                                      </Alert>
                                    </Col>
                                  </Item>
                                )
                              })}
                            </div>
                          </Col>
                        </Item>
                      </div>

                    </Col>
                  </Item>
                </Container>
                
                <Container className="py-3" fluid style={{ backgroundColor: 'white' }}>
                  <Item>
                    <Col
                      onClick={() => this.setState({ showgift: !showgift })}
                      xs={10} md={4}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-md-2 mt-3 mt-md-0">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Send Gift (Klik Disini)</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container fluid style={{ backgroundColor: 'white' }}
                  className={`text-center mt-3 aulia-wedding py-3 px-3 ${!showgift ? 'trsnone' : 'trs'}`} >
                  <Item>
                    <Col className="py-3 px-md-5 mx-2" style={{ backgroundColor: cmain, borderRadius: '10px' }}>
                      <h1>
                        Wedding Gift
                      </h1>
                      <Item>
                        <Col xs={8} md={4}>
                          <img src='https://i.ibb.co/5K53bBy/QR-BCA.png' className='p-2 w-100 img-fluid' />
                        </Col>
                      </Item>
                      <Item>
                        <p className="text-center w-100">
                          BCA - 1196058169 <br />
                            (Yossa Agung Pratama)
                          </p>
                        <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                          <Toast.Body>Copied!</Toast.Body>
                        </Toast>
                        <CopyToClipboard text="1196058169">
                          <Col xs={8} className='btn button mb-5 mt-1'
                            onClick={(e) => {
                              this.setState({ show: true })

                            }}>
                            Salin No. Rekening
                          </Col>
                        </CopyToClipboard>
                        <p className="text-center w-100">
                          BPD Jambi <br />
                            (Husna Daya Aulia)
                          </p>
                        <Toast onClose={() => this.setState({ show1: false })} show={this.state.show1} delay={3000} autohide >
                          <Toast.Body>Copied!</Toast.Body>
                        </Toast>
                        <CopyToClipboard text="1100013020596">
                          <Col xs={8} className='btn button mb-5 mt-1'

                            onClick={(e) => {
                              document.execCommand('1100013020596');
                              this.setState({ show1: true })
                            }}>
                            Salin No. Rekening
                          </Col>
                        </CopyToClipboard>
                        <p className="text-center w-100">
                          Alamat Pengiriman Kado
                          </p>
                        <p className="text-center w-100 px-3">
                          <b>
                            Jl. Pintu besi no 21 RT 28 Kel. Pal V Kec. kota baru Kota Jambi
                            </b>
                        </p>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container>
                <Container fluid style={{ backgroundColor: 'white' }} className="px-3">
                  <Item>
                    <Col xs={8} md={4}>
                      <img src="https://i.ibb.co/pQfmxGq/Terimakasih.png" className="w-100 img-fluid" />
                    </Col>
                  </Item>
                </Container>
                <Foot ig={logoig} dark />
              </div>
            </div>
          </div>
        </div>

      </>


    )
  }
}

